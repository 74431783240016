import moment from "moment";

// const barcodeRegex =
//   /<mm\sln="([^"]+)"\sfn="([^"]+)"\sdob="([\d]{2}\/[\d]{2}\/[\d]{2,4})"\spid="([^"]+)"\smrn="([^"]+)"\so="([^"]+)"\s.+dos="([\d]{2}\/[\d]{2}\/[\d]{2,4})"\s\/>/is;
/**
 *
 * @param {string} barcodeString
 * @returns
 */
export function emaBarcodeReader(barcodeString) {
  const isBarcode = /<mm.+\/>/is.test(barcodeString);
  function getPropertyFromBarcode(field) {
    const regex = new RegExp(`(?<=\\s${field}=")[^"]+`, "is");
    if (regex.test(barcodeString)) {
      return regex.exec(barcodeString)[0];
    }
    return "";
  }
  if (isBarcode) {
    return {
      patientLastName: getPropertyFromBarcode("ln"),
      patientFirstName: getPropertyFromBarcode("fn"),
      patientDOB: getPropertyFromBarcode("dob"),
      patientAccountNumber: getPropertyFromBarcode("pid"),
      patientMRN: getPropertyFromBarcode("mrn"),
      orderNumber: getPropertyFromBarcode("o"),
      collectedOn: getPropertyFromBarcode("dos")
    };
  }
  return null;
}

export function parseToDate(dateString, format = "MM/DD/YYYY") {
  const parsedDate = moment(dateString, format);
  if (parsedDate.format("MM/DD/YYYY") === dateString && parsedDate.isValid()) {
    return parsedDate.toDate();
  }
  return dateString;
}
