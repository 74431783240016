<template>
  <main
    class="mx-auto accession-wrapper container px-0 shadow-md"
    v-shortkey="shortkeys"
    @shortkey="toggleShortkey"
    data-testid="accessionSection"
  >
    <div class="accession-create">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center px-2">
          <physician-popup />
          <h2 ref="accessionHeader">Accessioning</h2>
          <loader
            class="d-inline-block mx-2"
            v-if="isSubmitting"
            size="small"
            data-testid="loader"
          />
        </div>
        <div v-if="lastAccessionLinkCase.caseId" class="previous__case" data-cy="prevCase">
          <button
            data-testid="prevCase"
            class="btn btn-link"
            @click="handleNavigateToCase(lastAccessionLinkCase.caseId)"
            role="link"
          >
            Previous --- {{ lastAccessionLinkCase.caseNumber }}
            <span data-private="redact"
              >{{ lastAccessionLinkCase.patientLastName }},
              {{ lastAccessionLinkCase.patientFirstName }}</span
            >
          </button>
        </div>
      </div>
      <div>
        <div class="header-panel">
          <icon-button
            data-testid="expandbtn"
            :icon="!isAccessionExpanded ? 'chevron-right' : 'chevron-up'"
            type="button"
            class="mr-4"
            @click="expand('isAccessionExpanded')"
          />
          <div v-if="hasPatientMatched" class="d-flex flex-row justify-content-start">
            <h2>Case</h2>
            <button class="btn btn-danger ml-3" @click.prevent="togglePatientHistoryPopup">
              History
            </button>
          </div>
          <div class="ml-auto text-primary font-weight-bold">
            <span class="mr-4">Cases: {{ sessionDetails.accessions }}</span>
            <span>Specimens: {{ sessionDetails.specimens }}</span>
          </div>
        </div>
        <Accession
          v-model="caseDetails"
          ref="accession"
          :isExpanded="isAccessionExpanded"
          :isAccessioned="isAccessioned"
          :isOrderLoaded="isOrderLoaded"
          v-stream:advance="advanceClick$"
          v-stream:quickSave="quickSaveClick$"
          @match="matchPatient"
          @loadBarcode="handleLoadBarCode"
          :isMatchRunning="isMatchRunning"
          :isMatchingOpen="isMatching"
          :hasPatientMatched="hasPatientMatched"
          :hasManuallyMatched="hasManuallyMatched"
        />
      </div>
      <div>
        <div class="header-panel">
          <icon-button
            type="button"
            :icon="!isDemographicsExpanded ? 'chevron-right' : 'chevron-up'"
            class="mr-4"
            @click="expand('isDemographicsExpanded')"
          />
          <h2>Demographics</h2>
          <h4 class="ml-auto" v-show="caseDetails.caseNumber">{{ caseDetails.caseNumber }}</h4>
        </div>

        <Demographics
          :validators="$v"
          ref="demographics"
          v-model="caseDetails"
          :isExpanded="isDemographicsExpanded"
          v-stream:save="demographicSaveClick$"
          :prefixDefaultPathologistId="prefixDefaultPathologistId"
          :isSubmitting="isSubmitting"
        />
      </div>
      <div>
        <div class="header-panel">
          <icon-button
            data-testid="expandbtn"
            type="button"
            :icon="!isSpecimensExpanded ? 'chevron-right' : 'chevron-up'"
            class="mr-4"
            @click="expand('isSpecimensExpanded')"
          />
          <h2>{{ specimensHeader }}</h2>
          <h4 class="ml-auto" v-show="caseDetails.caseNumber">{{ caseDetails.caseNumber }}</h4>
        </div>
        <Specimen
          data-testid="specimensForm"
          ref="specimenForm"
          v-model="caseDetails.specimens"
          :isExpanded="isSpecimensExpanded"
          v-stream:save="saveSpecimenClick$"
          @finalize="saveAndFinalize"
          v-if="(!isCytology && isAccessioned) || (isOrderLoaded && !labSettings.UseADTOrders)"
          :caseDetails="caseDetails"
          :currentPrefix="currentPrefix"
          :specimenNumberingOption="specimenNumbering"
          :prefixProtocols="prefixProtocols"
          :historyMatchingStatus="historyMatchingStatus"
        />
        <CytologyAccession
          data-testid="specimensForm"
          ref="specimenForm"
          v-model="caseDetails.specimens"
          :isExpanded="isSpecimensExpanded"
          v-stream:save="saveSpecimenClick$"
          @finalize="saveAndFinalize"
          v-else-if="isCytology && isAccessioned"
          :caseDetails="caseDetails"
          :currentPrefix="currentPrefix"
          :specimenNumberingOption="specimenNumbering"
          :prefixProtocols="prefixProtocols"
          :historyMatchingStatus="historyMatchingStatus"
        />
      </div>
    </div>
    <modal @close="toggleOrders" :status="isOrderPopupOpen">
      <HL7OrderPopup
        @close="toggleOrders"
        class="overflow-hidden"
        :searchQuery="caseDetails.orderNumber"
      />
    </modal>
    <modal :status="isMatching" @close="handleCloseMatching">
      <patient-matching-popup
        :matchedPatients="matchedPatients"
        @setPatient="manuallyMatchPatient"
      />
    </modal>
    <modal :status="isHistoryOpen" @close="togglePatientHistoryPopup">
      <CaseHistory :patientId="caseDetails.patientId" />
    </modal>
  </main>
</template>
<script>
import handleLoadOrder from "@/modules/handleLoadOrder.js";
import Specimen from "@/components/forms/Accession/Specimen.vue";
import Demographics from "@/components/forms/Accession/Demographics.vue";
import Accession from "@/components/forms/Accession/Accession.vue";
import { numeric, required, maxLength, minLength, helpers } from "vuelidate/lib/validators";
import { sortBy } from "lodash";
import {
  SSNValidator,
  maxFutureDaysForReceived,
  createPatientMatchPayload,
  dateRangeFilter,
  scrollToElement,
  removeExtraDivs,
  fixProviderPayload,
  removeTimeFromDatetime,
  getAltKeys,
  getNextSpecimenOrder,
  sanitizeHTML,
  getBinMapFileDrop
} from "@/modules/helpers.js";
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
import HL7OrderPopup from "../components/HL7OrderPopup.vue";
import Modal from "../components/common/Modal.vue";
import {
  Hl7OrdersApi,
  ReportsApi,
  InsuranceApi,
  DropdownApi,
  CasesApi,
  PrefixApi,
  MacrosApi,
  SpecimensApi,
  PrintersApi,
  CassettesApi,
  QuickLinksApi,
  ProvidersApi
} from "../services";
import eventBus, {
  OPEN_APPLICATION_SETTINGS,
  OPEN_ORDERS,
  fromBusEvent,
  LOAD_ORDERS,
  CLOSE_ORDERS
} from "../modules/eventBus";
import { switchMap, tap, catchError, skipUntil, map, exhaustMap } from "rxjs/operators";
import AuditLog from "../services/AuditLog";
import { createLogItem } from "../modules/helpers";
import DataSource from "devextreme/data/data_source";
import PatientMatchingPopup from "@/components/PatientMatchingPopup.vue";
import Loader from "@/components/common/Loader";
import accessionCompleteMode from "@/modules/accessionCompleteMode.js";
import specimenCompleteMode from "@/modules/specimenCompleteMode.js";
import IconButton from "@/components/common/IconButton.vue";
import physicianPopup from "@/mixins/physicianPopup.js";
import PhysicianPopup from "@/components/PhysicianPopup.vue";
import { CaseCompletionModes, CytTypeEnum, SpecimenNumbersEnum } from "@/modules/enums";
import Hermes from "hermes-channel";
import { handleErrors } from "@/modules/handleErrors";
import { printFileDrop } from "@/modules/printFileDrop";
import { handleMissingCptPairs } from "@/modules/handleMissingCptPairs";
import CaseHistory from "@/components/CaseHistory.vue";

export default {
  name: "AccessionEntry",
  components: {
    Demographics,
    Accession,
    Specimen,
    HL7OrderPopup,
    Modal,
    PatientMatchingPopup,
    Loader,
    PhysicianPopup,
    IconButton,
    CaseHistory
  },
  metaInfo: {
    title: "Accession",
    titleTemplate: "IntelliPath - %s"
  },
  provide() {
    return {
      loadedOrder: () => this.order?.orderNum
    };
  },
  mixins: [physicianPopup],
  created() {
    this.$store.dispatch("dropdowns/getSexes");
    this.$store.dispatch("dropdowns/getCasePhoneTypes");
    MacrosApi.getMacrosByUser(this.currentUser?.id || null)
      .load({ filter: [["macroType", 3], "and", dateRangeFilter("effectiveOn", "expiresOn")] })
      .then(res => {
        this.labProtocols = res || [];
      });
    Hermes.on("SET_DATA", this.checkBroadcastMessage);
  },
  beforeDestroy() {
    Hermes.off("SET_DATA", this.checkBroadcastMessage);
  },
  domStreams: ["quickSaveClick$", "demographicSaveClick$", "advanceClick$", "saveSpecimenClick$"],
  subscriptions() {
    const quickSave$ = this.quickSaveClick$.pipe(exhaustMap(() => this.quickSave()));
    const demographicSave$ = this.demographicSaveClick$.pipe(
      exhaustMap(() => this.saveDemographics())
    );
    const saveSpecimen$ = this.saveSpecimenClick$.pipe(exhaustMap(() => this.saveSpecimens()));
    const advance$ = this.advanceClick$.pipe(exhaustMap(() => this.advance()));
    const loadOrder$ = fromBusEvent(LOAD_ORDERS).pipe(
      map(async order => {
        this.isOrderPopupOpen = false;
        this.isDemographicsExpanded = false;
        this.isSpecimensExpanded = false;
        this.order = order;
        this.caseDetails = handleLoadOrder({
          order,
          caseDetails: this.caseDetails,
          specimenNumberingType: this.specimenNumbering,
          phoneTypes: this.phoneTypes,
          sexes: this.sexes,
          labProtocols: this.labProtocols,
          prefixProtocols: this.prefixProtocols,
          prefixDefaultProtocolId: this.currentPrefix?.protocolId
        });
        this.isOrderLoaded = true;
        this.$nextTick(() => {
          this.matchPatient(createPatientMatchPayload(this.caseDetails));
          if (this.labSettings?.UseADTOrders) {
            this.focusOnNumberOfSpecimens();
          } else {
            this.isDemographicsExpanded = this.expandDemo;
            this.isSpecimensExpanded = true;
            if (this.labSettings?.SkipDemographicsatAccessioning) {
              this.$refs.specimenForm.focusProtocol();
            } else if (!this.caseDetails.contacts.length) {
              this.$refs.accession.$refs.provider[0].focus();
            } else {
              this.$nextTick(() => {
                this.$refs.demographics.$refs.race.focus();
              });
            }
          }
        });
        return true;
      })
    );

    const openOrdersModal$ = fromBusEvent(OPEN_ORDERS).pipe(
      tap(() => (this.isOrderPopupOpen = true)),
      switchMap(() => loadOrder$),
      catchError(() => {
        return openOrdersModal$;
      })
    );
    const closeOrdersModal$ = fromBusEvent(CLOSE_ORDERS).pipe(
      tap(() => (this.isOrderPopupOpen = false)),
      skipUntil(openOrdersModal$)
    );
    return {
      openOrdersModal$,
      closeOrdersModal$,
      quickSave$,
      demographicSave$,
      advance$,
      saveSpecimen$
    };
  },
  data() {
    const today = moment(new Date()).format("yyyy-MM-DD");
    return {
      shortkeys: getAltKeys("cdefghlmnopry"),
      today,
      ///Order information
      isMatching: false,
      labProtocols: [],
      matchedPatients: [],
      isOrderPopupOpen: false,
      isOrderLoaded: false,
      order: null,
      isLoading: false,
      isSubmitting: false,
      hasPatientMatched: false,
      isDemographicsExpanded: false,
      isAccessionExpanded: true,
      isSpecimensExpanded: false,
      isAccessioned: false,
      currentPrefix: null,
      previousCase: null,
      caseDetails: {
        roomNumber: "",
        refNumber: "",
        billingCycle: "",
        billingType: "",
        caseCreationMethodId: 1,
        alternateId: "",
        labPrefix: "",
        caseNumber: 0,
        priority: "",
        orderNumber: "",
        numberOfSpecimens: 1,
        receivedOn: today,
        collectedOn: today,
        reportedOn: "",
        pathologists: [],
        contacts: [],
        patientLastName: "",
        patientFirstName: "",
        patientMiddleName: "",
        patientMaidenName: "",
        patientAccountNumber: "",
        private: false,
        reviewed: false,
        patientRace: "",
        patientDOB: "",
        patientSex: "",
        patientMRN: "",
        suffix: "",
        patientSSN: "",
        specimens: [],
        addresses: [],
        phoneNumbers: [],
        emails: [],
        patientTitle: ""
      },
      labPrinters: [],
      lastAccessionLinkCase: {},
      possibleDuplicate: false,
      isMatchRunning: false,
      prefixProtocols: [],
      hasManuallyMatched: false,
      hasMatchingPoppedUp: false,
      isHistoryOpen: false,
      historyMatchingStatus: "none",
      historyMatchingPayload: null,
      saveEventAfterMatch: null,
      isManuallyMatching: false,
      matchedPatientRecord: {},
      prefixDefaultContact: null
    };
  },
  validations() {
    return {
      caseDetails: {
        labPrefix: {
          required
        },
        caseNumber: {
          validCaseNumber: helpers.regex("CaseNumber", /^[0-9-]+$/)
        },
        priority: {
          required: value => (this.labSettings.PriorityOptional ? true : helpers.req(value))
        },
        orderNumber: {
          validOrderNumber: value => {
            // Must only be letters, numbers, or hyphens.
            return value ? /^[a-z0-9-]+$/i.test(value) : true;
          },
          maxLength: maxLength(30)
        },
        patientMRN: {
          maxLength: maxLength(30)
        },
        patientSSN: {
          maxLength: maxLength(12),
          minLength: minLength(9),
          SSNValidator() {
            if (this.permissions.SocialSecurityNumberView) {
              return SSNValidator;
            }
            return true;
          }
        },
        numberOfSpecimens: {
          numeric,
          required,
          maxLength: maxLength(3)
        },
        contacts: {
          required,
          onePrimary: value => {
            if (value) {
              const onePrimary = value.find(e => e.isPrimary);
              return Boolean(onePrimary);
            }
            return true;
          }
        },

        receivedOn: {
          required,
          maxFutureDaysForReceived: value =>
            maxFutureDaysForReceived(value, this.labSettings?.MaxFutureDaysForReceivedDate)
        },

        patientFirstName: {
          required,
          maxLength: maxLength(40)
        },
        patientLastName: {
          maxLength: maxLength(40),
          required
        }
      }
    };
  },
  watch: {
    isSubmitting(nv) {
      if (nv) {
        this.$el.style.cursor = "progress";
      } else {
        this.$el.style.cursor = "";
      }
    },
    "caseDetails.labPrefix": {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.loadPrefix(nv);
        }
      }
    },
    "caseDetails.patientMiddleName": function (value) {
      const { ForceUpperCasePatientName } = this.labSettings;
      if (ForceUpperCasePatientName) {
        if (parseInt(ForceUpperCasePatientName)) {
          this.caseDetails.patientMiddleName = value.toUpperCase();
        }
      }
    },
    "caseDetails.patientMaidenName": function (value) {
      if (value) {
        const { ForceUpperCasePatientName } = this.labSettings;
        if (ForceUpperCasePatientName) {
          if (parseInt(ForceUpperCasePatientName)) {
            this.caseDetails.patientMaidenName = value.toUpperCase();
          }
        }
      }
    },
    "caseDetails.addresses": {
      deep: true,
      handler(value) {
        if (value) {
          const { ForceUpperCaseAddress } = this.labSettings;
          value = value.map(entry => {
            for (value in entry) {
              if (ForceUpperCaseAddress) {
                if (ForceUpperCaseAddress) {
                  if (parseInt(ForceUpperCaseAddress) && typeof entry[value] === "string") {
                    entry[value] = entry[value].toUpperCase();
                  }
                }
              }
            }
            return entry;
          });
        }
      }
    },
    "caseDetails.contacts": {
      immediate: true,
      deep: true,
      handler(contacts) {
        const primaryProvider = contacts.find(e => e?.isPrimary);
        if (primaryProvider) {
          this.setContactBillingType(primaryProvider);
        }
      }
    },
    "caseDetails.receivedOn": {
      immediate: true,
      handler(nv, ov) {
        this.caseDetails.receivedOn = removeTimeFromDatetime(nv);
        if (removeTimeFromDatetime(nv) !== removeTimeFromDatetime(ov)) {
          const { DefaultDateCollected } = this.labSettings;
          if (DefaultDateCollected === 1) {
            this.caseDetails.collectedOn = nv;
          }
        }
      }
    },
    "caseDetails.collectedOn": {
      immediate: true,
      handler(collectedOn) {
        this.caseDetails.collectedOn = removeTimeFromDatetime(collectedOn);
      }
    },
    prefixDefaultContact: {
      immediate: true,
      handler(nv, ov) {
        if (!this.isOrderLoaded && nv?.contactId && nv.contactId !== ov?.contactId) {
          this.caseDetails.contacts = [{ ...nv, id: nv.contactId, isPrimary: true }];
        }
      }
    }
  },
  mounted() {
    this.loadSettings();
    this.loadPrevCase();
    if (
      !this.defaultLabelPrinter &&
      ((!this.prefixSpecLabelPrinter && !this.defaultSpecLabelPrinter) ||
        !this.defaultReqLabelPrinter)
    ) {
      window.notify("Please set a default label  printer before accessioning.", "warning");
      eventBus.$emit(OPEN_APPLICATION_SETTINGS);
    }
    PrintersApi.getLabPrinters().then(res => {
      this.labPrinters = res;
    });
    this.setLastAccession();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("accessionStore/clearStore");
    next();
  },
  computed: {
    ...mapState({
      labSettings: state => state.labSettings,
      sessionDetails: state => state.sessionDetails,
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      DefaultPathologistId: state => state.labSettings.DefaultPathologistId,
      defaultLabelPrinter: state => state.applicationSettings.defaultLabelPrinter,
      expandDemo: state => state.applicationSettings.expandDemo,
      defaultSpecLabelPrinter: state => state.applicationSettings.defaultSpecLabelPrinter,
      defaultReqLabelPrinter: state => state.applicationSettings.defaultReqLabelPrinter,
      defaultCassettePrinter: state => state.applicationSettings.defaultCassettePrinter,
      defaultGlassSlidePrinter: state => state.applicationSettings.defaultGlassSlidePrinter,
      DefaultEditorFontSize: state => state.labSettings.DefaultEditorFontSize,
      DefaultEditorFontFamily: state => state.labSettings.DefaultEditorFontFamily,
      IncludeSiteOnSpecimenLabel: state => state.labSettings.IncludeSiteOnSpecimenLabel,
      LabSettingDefaultCassettePrinter: state => state.labSettings.DefaultCassetteProcedurePrinter,
      autoOpenCase: state => state.applicationSettings.autoOpenCase,
      sexes: state => state.dropdowns.sexes,
      phoneTypes: state => state.dropdowns.casePhoneTypes,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      pathologistOptions: state => state.dropdowns.pathologists,
      prefixes: state => state.dropdowns.prefixes
    }),
    ...mapGetters(["permissions"]),
    isCytology() {
      //This getter determines if the current prefix is a cytology prefix.
      if (this.currentPrefix) {
        const { cytTypeId } = this.currentPrefix;
        if (cytTypeId) {
          const typeId = Number(this.currentPrefix.cytTypeId);
          if (!isNaN(typeId)) {
            return typeId !== CytTypeEnum.Surgical;
          }
        }
      }
      return false;
    },
    specimensHeader() {
      if (this.isCytology) {
        return "Clinical Data";
      }
      return "Specimens";
    },
    patientColumns() {
      return [
        { dataField: "firstName", dataType: "string" },
        { dataField: "lastName", dataType: "string" },
        { dataField: "maidenName", dataType: "string" },
        { dataField: "ssn", dataType: "string" },
        { dataField: "mrn", dataType: "string" },
        {
          dataField: "sex",
          lookup: {
            dataSource: this.sexes,
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        { dataField: "dateOfBirth", dataType: "date" },
        { dataField: "lastProvider", dataType: "string", caption: "Provider" },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    lastAccession() {
      return this.sessionDetails.lastAccession;
    },
    caseNumberDisplay() {
      if (this.caseDetails.caseNumber) {
        this.caseDetails.caseNumber;
      }
      return "";
    },
    specimenNumbering() {
      const primary = this.caseDetails?.contacts?.find(contact => contact.isPrimary);
      const contactSpecimenNumbering = primary?.properties?.specimenNumbering;
      if (
        [SpecimenNumbersEnum.Numbers, SpecimenNumbersEnum.Letters].includes(
          contactSpecimenNumbering
        )
      ) {
        return contactSpecimenNumbering;
      }
      return this.labSettings.SpecimenNumberingTypes;
    },
    prefixSpecLabelPrinter() {
      return (
        this.labPrinters?.find(e => e.printerId === this.currentPrefix?.specLabelPrinterId)?.id ||
        null
      );
    },
    prefixDefaultPathologistId() {
      if (this.currentPrefix?.pathologistId) {
        return this.currentPrefix.pathologistId;
      }
      return null;
    },
    disableForMatch() {
      return this.isMatching || this.isMatchRunning;
    }
  },
  methods: {
    ...mapActions("sessionDetails", ["accessionCase"]),
    checkBroadcastMessage(data) {
      this.$store.dispatch("sessionDetails/useImageFrame", data);
    },
    async handleNavigateToCase(caseId) {
      const confirm = await window.confirm(
        "You are going to be navigated away from this page. <br> Are you sure?"
      );
      if (!confirm) {
        return;
      }
      this.$router.push({ name: "CaseView", params: { caseId } });
    },
    handleLoadBarCode(barcodeOrder) {
      this.isOrderLoaded = true;
      this.order = {
        firstName: barcodeOrder.patientFirstName,
        lastName: barcodeOrder.patientLastName,
        bornOn: barcodeOrder.patientDOB,
        accountNum: barcodeOrder.patientAccountNumber,
        medicalRecordNum: barcodeOrder.patientMRN
      };
      this.matchPatient(createPatientMatchPayload(this.caseDetails));
      this.isDemographicsExpanded = this.expandDemo;
      this.isSpecimensExpanded = true;
    },
    toggleShortkey(event) {
      let refToFocus = null;
      function arrayToRef(ref) {
        if (Array.isArray(ref)) {
          return ref[0];
        }
        return ref;
      }
      switch (event.srcKey) {
        case "n":
          if (!this.$refs.accession.$refs.ssn.isFocused) {
            refToFocus = this.$refs.accession.$refs.ssn;
          } else {
            refToFocus = this.$refs.accession.$refs.numberOfSpecimens;
          }
          break;
        case "m":
          refToFocus = this.$refs.accession.$refs.mrn;
          break;
        case "d":
          refToFocus = this.$refs.accession.$refs.dob;
          break;
        case "y":
          refToFocus = this.$refs.accession.$refs.priority;
          break;
        case "e":
          if (
            !this.$refs.accession.$refs.receivedOn.isFocused &&
            !this.$refs.accession.$refs.receivedOn[0].isFocused
          ) {
            refToFocus = this.$refs.accession.$refs.receivedOn;
          } else {
            refToFocus = this.$refs.accession.$refs.collectedOn;
          }
          break;
        case "p":
          if (
            this.isAccessionExpanded &&
            !arrayToRef(this.$refs.accession.$refs.provider).isFocused
          ) {
            refToFocus = arrayToRef(this.$refs.accession.$refs.provider);
          } else if (!this.$refs.demographics.$refs?.pathologist?.isFocused) {
            refToFocus = this.$refs.demographics.$refs.pathologist;
          }
          break;
        case "l":
          refToFocus = this.$refs.accession.$refs.lastName;
          break;
        case "f":
          refToFocus = this.$refs.accession.$refs.firstName;
          break;
        case "c":
          if (this.isSpecimensExpanded) {
            refToFocus = this.$refs.specimenForm.$refs.clinical;
          }
          break;
        case "g":
          if (this.isSpecimensExpanded) {
            refToFocus = this.$refs.specimenForm.$refs.gross;
          }
          break;
        case "o":
          if (this.isSpecimensExpanded) {
            refToFocus = this.$refs.specimenForm.$refs.protocol;
          }
          break;
        case "h":
          if (this.isSpecimensExpanded) {
            refToFocus = this.$refs.specimenForm.$refs.hopper;
          }
          break;
        case "r":
          refToFocus = this.$refs.accession.$refs.patientSex[0];
          break;
        default:
          break;
      }
      if (refToFocus) {
        refToFocus = arrayToRef(refToFocus);
        refToFocus.focus();
      }
    },
    toggleOrders() {
      this.isOrderPopupOpen = !this.isOrderPopupOpen;
    },
    validate() {
      const labId = this.currentLab;
      const {
        contacts,
        patientFirstName,
        patientLastName,
        patientDOB,
        patientMRN,
        patientSSN,
        numberOfSpecimens,
        orderNumber
      } = this.caseDetails;
      const primaryProvider = contacts.find(e => e?.isPrimary);
      return CasesApi.validate({
        labId,
        patientFirstName,
        patientLastName,
        patientDOB,
        patientMRN,
        patientSSN,
        contacts: [
          {
            contactId: primaryProvider.contactId,
            dsplayName: primaryProvider.displayName,
            isPrimary: true
          }
        ],
        numberOfSpecimens,
        orderNumber
      });
    },

    async quickSave() {
      if (this.isSubmitting) {
        return;
      }
      const checkHistory = await this.checkHistory(this.quickSave);
      if (!checkHistory) {
        return;
      }
      this.isSubmitting = true;
      if (this.isOrderLoaded && !this.labSettings.UseADTOrders) {
        return this.saveDemographics();
      }
      try {
        const response = await this.save();
        if (response.caseId) {
          // Check for Accessioning  Completion Mode on Prefix
          if (
            this.currentPrefix &&
            this.currentPrefix.caseCompletionModeId === CaseCompletionModes.Accession
          ) {
            await accessionCompleteMode(this.currentPrefix, response);
            window.notify("Triggering accession complete mode.");
          }
          if (this.$refs?.accession?.isRegistrationNotePrefix) {
            this.handleRegistrationNotes(response, this.caseDetails.registrationNotes);
          }
          this.reset(response);
          this.setLastAccession();
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async printSpecLabelsWithSite(caseId) {
      const { labelPrintAmount, labelPrinterOverride } = this.sessionDetails;
      const { SpecimenContainerLabelsPerSpecimen } = this.labSettings;
      let specLabelCopies = labelPrinterOverride
        ? labelPrintAmount
        : SpecimenContainerLabelsPerSpecimen;
      if (this.currentPrefix) {
        if (!this.currentPrefix?.printSpecLabel) {
          return;
        } else if (this.currentPrefix.specLabelCopies > specLabelCopies) {
          specLabelCopies = this.currentPrefix.specLabelCopies;
        }
      }
      try {
        await ReportsApi.getLabelsInRange({
          fromCaseId: caseId,
          toCaseId: caseId,
          specPrinterId: this.prefixSpecLabelPrinter || this.defaultSpecLabelPrinter,
          reqPrinterId: this.defaultReqLabelPrinter,
          printerId: this.defaultLabelPrinter,
          specLabelCopies,
          reqLabelCopies: 0
        });
      } catch (error) {
        window.notify("Error printing specimen labels.", "warning");
      }
    },
    handleError(error) {
      this.isSubmitting = false;
      if (error.response?.data) {
        if (error.response.data?.message) {
          return window.alert(error.response.data.message, "error");
        }
        const { errors } = error.response.data;
        if (errors) {
          const errorHTML = `<ul>
                <li>${Object.keys(errors).map(e => {
                  return `${e}: ${errors[e].join("<br>")}`;
                })}</li>
              </ul>`;
          return window.alert(errorHTML);
        }
        if (typeof error.response.data === "string") {
          return window.alert(error.response.data, "error");
        }
        return window.notify("Error saving case.", "error");
      }
      if (error.message) {
        return window.alert(error.message, "error");
      }
      window.notify("Error saving case", "error");
    },
    async validateForms() {
      if (
        !this.defaultLabelPrinter &&
        (!this.defaultReqLabelPrinter ||
          (!this.prefixSpecLabelPrinter && !this.defaultSpecLabelPrinter))
      ) {
        window.alert("Please select a default label printer before accessioning.", "warning");
        eventBus.$emit(OPEN_APPLICATION_SETTINGS);
        throw new Error("No printer found please select one.");
      }
      this.removeEmptyContactInfo();
      const accessionForm = this.$refs.accession;
      const demographicsForm = this.$refs.accession;
      const specimenForm = this.$refs.specimen;
      const mainForm = this.$v.caseDetails;

      /**
       * Check validation on the accesion form.
       */
      if (accessionForm.$v.$invalid) {
        scrollToElement(accessionForm.$el);
        accessionForm.$v.$touch();
        throw new Error("Validation failed please the accession section & try again.");
      } else if (accessionForm?.$v) {
        accessionForm.$v.$reset();
      }
      if (demographicsForm?.$v?.$invalid) {
        scrollToElement(demographicsForm.$el);
        demographicsForm.$v.$touch();
        throw new Error("Validation failed please the demographic section & try again.");
      } else if (demographicsForm?.$v) {
        demographicsForm.$v.$reset();
      }
      if (specimenForm?.$v?.$invalid) {
        scrollToElement(specimenForm.$el);
        specimenForm.$v.$touch();
        throw new Error("Validation failed please the specimen section & try again.");
      } else if (specimenForm?.$v) {
        specimenForm.$v.$reset();
      }
      if (mainForm?.$invalid) {
        throw new Error("Validation failed please check your input & try again.");
      } else {
        mainForm.$reset();
      }

      const { possibleDuplicate, highSpecimenCount } = await this.validate();
      let confirmStatus = true;
      confirmStatus = await this.checkAge();
      if (!confirmStatus) {
        throw new Error("User cancelled operation");
      }
      if (possibleDuplicate) {
        this.possibleDuplicate = true;
        confirmStatus = await window.confirm(
          `This accession may be a duplicate, do you wish to continue?`
        );
        if (!confirmStatus) {
          throw new Error("User cancelled operation");
        }
      }
      if (highSpecimenCount) {
        const threshold = this.labSettings.HighNumberOfSpecThreshold || 10;
        confirmStatus = await window.confirm(
          `This accession has more than ${threshold} specimens, do you wish to continue?`
        );
        if (!confirmStatus) {
          throw new Error("User cancelled operation");
        }
      }
      this.$store.commit("accessionStore/setCaseDetails", this.caseDetails);
      confirmStatus = await this.getPhysicianPopup(1); //Accession Case
      if (!confirmStatus) {
        throw new Error("User cancelled operation");
      }
      return confirmStatus;
    },
    async save() {
      const labId = this.currentLab;
      let labLocationId = this.currentLabLocation;
      if (!labLocationId) {
        labLocationId = this.availableLabLocations[0].id;
      }

      await this.validateForms();
      if (this.caseDetails.caseNumber === 0) {
        delete this.caseDetails.caseNumber;
      }
      if (
        this.isOrderLoaded &&
        this.labSettings.InsuranceNameWhenNoInterfaceMatch &&
        this.order?.hL7Insurance?.length
      ) {
        let hasNonMatchingInsurance = false;
        for (const insurance of this.order.hL7Insurance) {
          if (insurance?.code === this.labSettings.InsuranceNameWhenNoInterfaceMatch) {
            hasNonMatchingInsurance = true;
            break;
          }
        }
        if (hasNonMatchingInsurance) {
          await window.alert(
            "All insurances were not able to be mapped automatically. Please go to the insurance area and map the correct insurances."
          );
        }
      }
      const { labelPrintAmount, labelPrinterOverride } = this.sessionDetails;
      const { RequisitionLabelsPerAccession, SpecimenContainerLabelsPerSpecimen } =
        this.labSettings;
      //Ticket
      let specLabelCopies = labelPrinterOverride
        ? labelPrintAmount
        : SpecimenContainerLabelsPerSpecimen;
      let reqLabelCopies = RequisitionLabelsPerAccession ?? null;
      //If the lab setting is set to include the site on the specimen we will only
      //Check prefix settings to override values if they are greater.
      let caseNumber = 0;
      if (this.currentPrefix) {
        if (!this.currentPrefix?.printSpecLabel) {
          specLabelCopies = 0;
        } else if (this.currentPrefix.specLabelCopies > specLabelCopies) {
          specLabelCopies = this.currentPrefix.specLabelCopies;
        }
        if (!this.currentPrefix?.printReqLabel) {
          reqLabelCopies = 0;
        } else if (this.currentPrefix.reqLabelCopies > reqLabelCopies) {
          reqLabelCopies = this.currentPrefix.reqLabelCopies;
        }
        //! Check if the case number has been manipulated from 0.
        if (this.caseDetails.caseNumber && !this.caseDetails.caseId) {
          caseNumber = this.caseDetails.caseNumber.replace("-", "");
        }
      }
      if (this.IncludeSiteOnSpecimenLabel) {
        specLabelCopies = 0;
      }
      const numberOfSpecimens = this.caseDetails.numberOfSpecimens;
      this.caseDetails.HL7OrderId = this.order?.id;
      this.caseDetails.contacts = fixProviderPayload(this.caseDetails.contacts);
      const caseInserted = await CasesApi.insertCase(
        {
          ...this.caseDetails,
          labId,
          caseNumber,
          print: {
            specLabelCopies,
            reqLabelCopies, //If the value is null it will look at the prefix to determine the print amount.
            specPrinterId: this.prefixSpecLabelPrinter || this.defaultSpecLabelPrinter,
            reqPrinterId: this.defaultReqLabelPrinter,
            printerId: this.defaultLabelPrinter
          }
        },
        labLocationId
      );
      caseInserted.numberOfSpecimens = numberOfSpecimens;
      this.$store.commit("accessionStore/setCaseDetails", caseInserted);
      if (caseInserted.printError) {
        window.notify(caseInserted.printError, "warning", 12000);
      }
      //Update the form state with the returned inserted values.
      this.caseDetails.caseId = caseInserted.caseId;
      this.caseDetails.patientId = caseInserted.patientId;
      this.caseDetails.status = caseInserted.status;
      this.caseDetails.private = caseInserted.private;
      if (caseInserted) {
        const caseLogItem = createLogItem(caseInserted, 0);
        caseLogItem.comments = JSON.stringify(
          {
            ...caseInserted,
            possibleDuplicate: this.possibleDuplicate,
            historyMatching: {
              historyMatchingStatus: this.historyMatchingStatus,
              payload: this.historyMatchingPayload,
              matchedPatient: this.matchedPatientRecord
            }
          },
          null,
          2
        );
        AuditLog.insertLogMessage(caseLogItem);
        this.$v.$reset();
        this.isAccessioned = true;
        return caseInserted;
      } else {
        this.isAccessioned = false;
      }
    },

    async saveDemographics() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      try {
        if (this.isOrderLoaded) {
          return this.handleSaveAccessionOrder(this.caseDetails);
        }
        if (this.caseDetails.specimens.length) {
          return this.saveSpecimens();
        }
        const prevCase = await this.updateCase(this.caseDetails);
        this.reset(prevCase);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async createCaseSpecimens(specimens) {
      const createdSpecimens = [];
      for (const specimen of specimens) {
        if (specimen.gross && specimen.site) {
          specimen.gross = specimen.gross.replace(/@site@/gi, specimen.site);
        }
        specimen.clinical = removeExtraDivs(specimen.clinical);
        specimen.gross = removeExtraDivs(specimen.gross);
        sanitizeHTML(specimen, this.labSettings);
        const response = await SpecimensApi.createSpecimen({
          ...specimen,
          numberOfBlocks: 1,
          duringAccessioning: true
        });
        handleMissingCptPairs(response);
        const updatedSpecimen = await this.increaseSpecimenBlockNum({
          ...specimen,
          id: response.id,
          cassettes: response.cassettes
        });
        createdSpecimens.push(updatedSpecimen);
      }
      this.$store.dispatch("sessionDetails/increaseSpecimenCount", createdSpecimens.length);
      return createdSpecimens;
    },
    async increaseSpecimenBlockNum(specimen) {
      const protocol = this.prefixProtocols.find(e => e.id === specimen.protocolId);
      if (protocol?.blocks && protocol.blocks !== specimen.numberOfBlocks) {
        await SpecimensApi.increaseCassetteQty(specimen.id, specimen.numberOfBlocks);
      }
      return specimen;
    },
    async handleSaveAccessionOrder(caseDetails) {
      try {
        this.isSubmitting = true;
        const caseInserted = await this.save();
        if (caseInserted) {
          caseDetails.specimens.forEach(e => {
            e.caseId = caseInserted.caseId;
          });
          caseDetails.caseId = caseInserted.caseId;
          caseDetails.caseNumber = caseInserted.caseNumber;
          caseDetails.status = caseInserted.status;
          caseInserted.specimens = await this.createCaseSpecimens(caseDetails.specimens);
          if (this.order?.id) {
            if (this.order?.insurances?.length) {
              await Promise.all(
                this.order.insurances.map(policy => {
                  InsuranceApi.addPolicy({ ...policy, caseId: caseDetails.caseId });
                })
              );
            }
            await Hl7OrdersApi.activateOrder(this.order.id, false);
          }
          if (
            this.currentPrefix &&
            this.currentPrefix.caseCompletionModeId === CaseCompletionModes.Accession
          ) {
            await accessionCompleteMode(this.currentPrefix, caseInserted);
            window.notify("Triggering accession complete mode.");
          }
          this.reset(caseInserted);
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async saveAndFinalize() {
      if (this.isSubmitting) {
        return;
      }
      try {
        this.isSubmitting = true;
        if (this.isOrderLoaded) {
          const targetSpecimens = [...(this.caseDetails.specimens || [])];
          this.caseDetails = await this.save();
          this.caseDetails.specimens = await this.createCaseSpecimens(
            targetSpecimens.map(e => {
              return { ...e, caseId: this.caseDetails.caseId };
            })
          );
          if (this.order?.id) {
            if (this.order?.insurances?.length) {
              await Promise.all(
                this.order.insurances.map(policy => {
                  InsuranceApi.addPolicy({ ...policy, caseId: this.caseDetails.caseId });
                })
              );
            }
            await Hl7OrdersApi.activateOrder(this.order.id, false);
          }
        } else {
          this.caseDetails.specimens = await this.createCaseSpecimens(this.caseDetails.specimens);
        }
        const prevCase = await specimenCompleteMode(this.currentPrefix, this.caseDetails);
        window.notify("Triggering specimen complete mode.");
        this.reset(prevCase);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async saveSpecimens() {
      if (this.isSubmitting) {
        return;
      }
      try {
        this.isSubmitting = true;
        if (this.isOrderLoaded && !this.labSettings.UseADTOrders) {
          return this.handleSaveAccessionOrder(this.caseDetails);
        }
        this.caseDetails.specimens = await this.createCaseSpecimens(this.caseDetails.specimens);
        const prevCase = await this.updateCase(this.caseDetails);
        this.reset(prevCase);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async advance() {
      const checkHistory = await this.checkHistory(this.advance);
      if (!checkHistory) {
        return;
      }
      if (this.isSubmitting) {
        return;
      }
      if (this.isOrderLoaded && !this.labSettings.UseADTOrders) {
        return this.saveDemographics();
      }
      try {
        this.isSubmitting = true;
        const response = await this.save();
        this.caseDetails = { ...this.caseDetails, ...response };

        this.isAccessionExpanded = false;
        this.isAccessioned = true;
        this.isSpecimensExpanded = true;
        if (this.labSettings.UseADTOrders && this.isOrderLoaded) {
          this.$refs.specimenForm.loadNumberOfSpecimens();
        }
        this.isDemographicsExpanded = this.expandDemo;
        this.accessionCase({
          ...response,
          numberOfSpecimens: this.caseDetails.numberOfSpecimens
        });
        this.setLastAccession();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    expand(prop) {
      if (this.isAccessioned || this.isOrderLoaded) {
        this[prop] = !this[prop];
      }
    },
    updateCase(caseDetails) {
      return CasesApi.updateCase(caseDetails).then(response => {
        if (response?.lastDeepChangeOn) {
          caseDetails.lastDeepChangeOn = response.lastDeepChangeOn;
        }
        return response;
      });
    },
    async matchPatient(data) {
      this.isMatchRunning = true;
      try {
        if (data === "manual") {
          this.isManuallyMatching = true;
          const store = new DataSource({
            store: CasesApi.patientMatch,
            sort: { selector: "createdOn", desc: true }
          });
          this.isMatching = true;
          this.matchedPatients = store;
          return;
        }
        this.isLoading = true;
        if (this.isAccessioned) {
          return;
        }
        if (!this.hasPatientMatched) {
          try {
            this.historyMatchingPayload = data;
            const matchedPatients = await CasesApi.matchPatient(data);
            if (matchedPatients?.data?.length) {
              // If backend match returns isExact, apply patient data
              if (matchedPatients?.data[0]?.isExact) {
                const match = matchedPatients.data[0];
                this.hasPatientMatched = true;
                this.historyMatchingStatus = "automatic";
                if (typeof match.id === "number") {
                  const patientRecord = await CasesApi.getPatientById(match.id);
                  if (patientRecord?.id === match?.id) {
                    this.setPatient(patientRecord);
                  }
                }
              } else if (!this.hasMatchingPoppedUp) {
                //If there is patient records to match we will display the grid.
                this.hasMatchingPoppedUp = true;
                this.isMatching = true;
                this.matchedPatients = matchedPatients.data;
              }
            } else {
              this.historyMatchingStatus = "no match";
            }
          } catch (error) {
            this.historyMatchingStatus = `error: ${error?.message}`;
            let message = "Error loading patient match information.";
            if (error?.message) {
              message += `: "${error.message}"`;
            }
            window.notify(message, "error");
          }
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isMatchRunning = false;
        this.isLoading = false;
        if (this.saveEventAfterMatch) {
          this.saveEventAfterMatch();
        }
      }
    },
    async setPatient(patientRecord) {
      if (this.isOrderLoaded) {
        const currentOrder = this.order;
        if (patientRecord.firstName !== currentOrder.firstName) {
          patientRecord.firstName = currentOrder.firstName;
        }
        if (patientRecord.lastName !== currentOrder.lastName) {
          patientRecord.lastName = currentOrder.lastName;
        }
        if (patientRecord.dateOfBirth !== currentOrder.bornOn) {
          patientRecord.dateOfBirth = currentOrder.bornOn;
        }
        if (patientRecord.accountNumber !== currentOrder.accountNum) {
          patientRecord.accountNumber = currentOrder.accountNum;
        }
        if (patientRecord.mrn !== currentOrder.medicalRecordNum) {
          patientRecord.mrn = currentOrder.medicalRecordNum;
        }
      }
      if (patientRecord?.id) {
        this.caseDetails.patientId = patientRecord.id;
      }
      if (this.labSettings.AccessionPatientHistoryFields) {
        const settingsFromJson = JSON.parse(this.labSettings.AccessionPatientHistoryFields);
        if (!settingsFromJson.mrn) {
          patientRecord.mrn = null;
        }
        if (!settingsFromJson.accountNumber) {
          patientRecord.accountNumber = null;
        }
      }

      const fieldMap = [
        ["patientSSN", "ssn"],
        ["patientDOB", "dateOfBirth"],
        ["patientMRN", "mrn"],
        ["patientSex", "sex"],
        ["patientAccountNumber", "accountNumber"],
        ["patientFirstName", "firstName"],
        ["patientLastName", "lastName"],
        ["patientMiddleName", "middleName"],
        ["patientRace", "race"],
        ["alternateId", "alternateId"],
        ["patientMaidenName", "maidenName"],
        ["suffix", "suffix"]
      ];
      this.hasPatientMatched = true;
      for (const item of fieldMap) {
        if (!this.caseDetails[item[0]] && ![null, undefined, ""].includes(patientRecord[item[1]])) {
          this.caseDetails[item[0]] = patientRecord[item[1]];
        }
      }
      const { phoneNumbers, emails, addresses } = this.caseDetails;
      if (!emails?.length && patientRecord.emails) {
        this.caseDetails.emails = patientRecord.emails;
      }
      if (!addresses?.length && patientRecord.addresses) {
        this.caseDetails.addresses = patientRecord.addresses;
      }
      if (!addresses?.length && patientRecord.address) {
        this.caseDetails.addresses = patientRecord.address;
      }
      if (!phoneNumbers?.length && patientRecord.phoneNumbers) {
        this.caseDetails.phoneNumbers = patientRecord.phoneNumbers;
      }
      this.matchedPatientRecord = {
        patientId: patientRecord.id,
        firstName: patientRecord.firstName,
        lastName: patientRecord.lastName,
        dob: patientRecord.dateOfBirth,
        mrn: patientRecord.mrn,
        ssn: patientRecord.ssn
      };
      this.isMatching = false;
    },
    loadPrevCase() {
      const { DefaultProviderIsLastUsed } = this.labSettings;
      // *** (1) If the "DefaultProviderIsLastUsed" setting is true, the provider will default to the last one used.
      const lastProvider = this.sessionDetails?.lastProvider;
      this.isAccessionExpanded = true;
      this.isDemographicsExpanded = false;
      this.isSpecimensExpanded = false;
      this.$v.$reset();
      if (this.prefixDefaultContact) {
        this.caseDetails.contacts = [
          { ...this.prefixDefaultContact, id: this.prefixDefaultContact.id, isPrimary: true }
        ];
      } else if (DefaultProviderIsLastUsed && lastProvider) {
        const alreadyInUse = this.caseDetails.contacts.some(
          e => e.contactId === lastProvider.contact.contactId
        );
        if (!alreadyInUse) {
          this.caseDetails.contacts.push(lastProvider);
        }
      }
    },
    async printBlocks(caseDetails) {
      try {
        const fileDropSettings = JSON.parse(this.labSettings.FileDropPrintingConfiguration);
        const fileDropAtAccessioning = fileDropSettings?.atAccessioning;
        const cassettePrinter =
          this.defaultCassettePrinter ?? this.LabSettingDefaultCassettePrinter;
        const { specimens } = caseDetails;
        const protocolBinMaps = new Set();
        const protocols = await DropdownApi.getProtocol(caseDetails.labPrefix);
        if (
          fileDropAtAccessioning &&
          (!cassettePrinter ||
            cassettePrinter === parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID))
        ) {
          const defaultFileDropTray = fileDropSettings?.defaultTray;
          const labBinMaps = await PrintersApi.getLabBinMaps();
          const allCassettes = await ReportsApi.cassetteListStore.load({
            filter: ["caseId", caseDetails.caseId],
            sort: [
              { selector: "specimenOrder", desc: false },
              { selector: "blockNum", desc: false }
            ]
          });
          let payload = [];
          const cases = await CasesApi.getFileDropPrintInfo([caseDetails.caseId]);
          const { casePriorityBinMap, contactBinMap } = cases[0];
          const prefix = this.prefixes.find(e => e.id === caseDetails.labPrefix);
          for (const cassette of allCassettes) {
            let trayName = defaultFileDropTray;
            const { protocolId } = cassette;
            const targetProtocol = protocols.find(e => e.id === protocolId);
            const binMapTray = getBinMapFileDrop({
              protocol: targetProtocol,
              labBinMaps,
              prefix,
              priorityBinMapId: casePriorityBinMap,
              contactBinMapId: contactBinMap
            });
            if (binMapTray) {
              trayName = binMapTray;
            }
            payload.push({
              ...cassette,
              cassetteTrayName: trayName,
              patientFirstName: caseDetails.patientFirstName,
              patientLastName: caseDetails.patientLastName
            });
          }
          await printFileDrop(payload);
          window.notify(`Printed ${payload.length} cassette label(s).`);
          const cassettesToMark = payload.map(e => e.cassetteId);
          await CassettesApi.markCassettesAsPrinted(cassettesToMark);
        } else if (cassettePrinter) {
          specimens.forEach(specimen => {
            if (specimen.protocolId) {
              const { protocolId } = specimen;
              const targetProtocol = protocols.find(e => e.id === protocolId);
              if (targetProtocol?.binMapId) {
                protocolBinMaps.add(targetProtocol.binMapId);
              }
            }
          });
          const labBinMapOrder = this.labSettings.LabBinPrintOrder;
          const isProtocolTarget =
            typeof labBinMapOrder === "string" &&
            labBinMapOrder?.indexOf &&
            labBinMapOrder?.indexOf("protocol") === 0;
          // *** (1) If the labBinOrder has the protocol first.
          // *** (2) And if we found atleast 1 bin map on the specimen list.
          // *** (3) Then we will have to dispatch the prints one by one.

          const usesBatch = this.labSettings.CassettePrintingUsesBatching;
          if (protocolBinMaps.size > 1 || (isProtocolTarget && protocolBinMaps.size > 0)) {
            // *** ticket/IP-285
            // *** Request the latest cassettes by caseId from the database to print them 1 by 1.

            const allCassettes = await ReportsApi.cassetteListStore.load({
              filter: ["caseId", caseDetails.caseId],
              sort: [
                { selector: "specimenOrder", desc: false },
                { selector: "blockNum", desc: false }
              ]
            });
            if (usesBatch) {
              await ReportsApi.printBlockLabelsBatch({
                printerId: cassettePrinter,
                labId: this.currentLab,
                cassetteIds: allCassettes.map(e => e.cassetteId)
              });
            } else {
              for (const cassette of allCassettes) {
                // ! Protocol BinMaps are dependent on sending the specimen Id!
                await ReportsApi.printBlockLabels({
                  printerId: cassettePrinter,
                  labId: this.currentLab,
                  numberOfCopies: 1,
                  specimenId: cassette.specimenId,
                  cassetteId: cassette.cassetteId,
                  caseId: caseDetails.caseId
                });
              }
            }
            window.notify(`Printed ${allCassettes.length} cassette label(s).`);
          } else {
            // *** In this scenario, we can print all the cassettes at once but protocol binmaps will be ignored..
            if (usesBatch) {
              const allCassettes = await ReportsApi.cassetteListStore.load({
                filter: ["caseId", caseDetails.caseId],
                sort: [
                  { selector: "specimenOrder", desc: false },
                  { selector: "blockNum", desc: false }
                ]
              });
              await ReportsApi.printBlockLabelsBatch({
                printerId: cassettePrinter,
                labId: this.currentLab,
                cassetteIds: allCassettes.map(e => e.cassetteId)
              });
            } else {
              await ReportsApi.printBlockLabels({
                printerId: cassettePrinter,
                labId: this.currentLab,
                numberOfCopies: 1,
                caseId: caseDetails.caseId
              });
            }
            window.notify(`Printed all cassette label(s).`);
          }
        }
      } catch (e) {
        handleErrors(e);
      }
    },
    async printSlides(caseDetails) {
      const { specimens } = caseDetails;
      const allCassettes = specimens.reduce((acc, specimen) => {
        if (specimen.protocolId) {
          return [
            ...acc,
            ...specimen.cassettes.map(cassette => ({
              ...cassette,
              specimenOrder: specimen.specimenOrder
            }))
          ];
        }
        return acc;
      }, []);
      const slidePrinter = this.defaultGlassSlidePrinter;
      if (!slidePrinter) {
        window.alert("Slides failed to print due to no default slide printer found.");
      } else {
        const slides = sortBy(
          allCassettes.reduce((list, cassette) => {
            if (cassette.slides?.length) {
              return [
                ...list,
                ...cassette.slides.map(slide => ({
                  ...slide,
                  specimenId: cassette.specimenId,
                  cassetteId: cassette.id,
                  specimenOrder: cassette.specimenOrder
                }))
              ];
            }
            return list;
          }, []),
          ["specimenOrder", "cassetteId", "slideLabel"]
        );
        if (slides.length) {
          if (this.labSettings.SlidePrintingUsesBatching) {
            ReportsApi.printGlassSlidesBatch({
              printerId: slidePrinter,
              labId: this.currentLab,
              slideIds: slides.map(e => e.id)
            })
              .then(() => {
                window.notify(`Printed ${slides.length} slide(s).`);
              })
              .catch(() => {
                window.notify("Error printing slide labels.", "warning");
              });
          } else {
            let successfulSlides = 0;
            for (const slide of slides) {
              await ReportsApi.printGlassSlideLabels({
                slideId: slide.id,
                caseId: caseDetails.caseId,
                specimenId: slide.specimenId,
                numberOfCopies: 1,
                printerId: slidePrinter
              })
                .then(() => {
                  successfulSlides++;
                })
                .catch(() => {
                  window.notify("Error printing slide labels.", "warning");
                });
            }
            if (successfulSlides) {
              window.notify(`Printed ${slides.length} slide(s).`);
            }
          }
        }
      }
    },
    async reset(previousCase = {}) {
      this.hasPatientMatched = false;
      this.isAccessioned = false;
      this.isOrderLoaded = false;
      this.hasManuallyMatched = false;
      this.hasMatchingPoppedUp = false;
      this.order = null;
      this.possibleDuplicate = false;
      this.historyMatchingStatus = "none";
      this.historyMatchingPayload = null;
      this.matchedPatientRecord = {};
      this.$store.commit("accessionStore/clearStore");
      this.caseDetails = {
        roomNumber: "",
        refNumber: "",
        billingCycle: "",
        caseCreationMethodId: 1,
        billingType: "",
        alternateId: "",
        labPrefix: previousCase.labPrefix ?? "",
        caseNumber: 0,
        priority: this.labSettings.DefaultPriority,
        orderNumber: "",
        numberOfSpecimens: 1,
        receivedOn: this.today,
        collectedOn: this.today,
        reportedOn: "",
        pathologists: this.labSettings.PathologistsResetBetweenAccessions
          ? []
          : previousCase.pathologists ?? [],
        contacts: [],
        patientLastName: "",
        patientFirstName: "",
        patientMiddleName: "",
        patientMaidenName: "",
        patientRace: "",
        patientDOB: "",
        patientSex: "",
        patientMRN: "",
        patientSSN: "",
        specimens: [],
        addresses: [],
        phoneNumbers: [],
        emails: [],
        registrationNotes: []
      };
      if (this.labSettings.PathologistsResetBetweenAccessions) {
        let nextPathologistId = null;
        if (this.currentPrefix.pathologistId) {
          nextPathologistId = this.currentPrefix.pathologistId;
        } else {
          nextPathologistId = this.DefaultPathologistId;
        }
        if (nextPathologistId) {
          const nextPathologist = this.pathologistOptions.find(e => e.id === nextPathologistId);
          if (nextPathologist) {
            this.caseDetails.pathologists = [nextPathologist];
          }
        }
      }
      if (this.autoOpenCase) {
        window.open(`/accession/${previousCase.caseId}/?isPreview=true`);
      }
      this.matchedPatients = CasesApi.patientMatch;
      this.$nextTick(() => {
        if (this.$refs?.accessionHeader?.scrollIntoView) {
          this.$refs.accessionHeader.scrollIntoView();
        }
        //Moves user focus back to the top of the screen where prefix is.
        if (
          this.currentPrefix?.numberingType === "Manual" &&
          this.$refs.accession?.$refs?.caseNumber
        ) {
          const caseNumberField = this.$refs.accession.$refs.caseNumber;
          caseNumberField.focus();
          caseNumberField.selectAll();
        } else if (this.$refs.accession?.$refs?.orderNumber) {
          this.$refs.accession.$refs.orderNumber.focus();
        }
      });
      this.loadSettings();
      const numberOfSpecimens = previousCase.specimens?.length
        ? previousCase.specimens.length
        : previousCase.numberOfSpecimens;
      this.accessionCase({
        ...previousCase,
        numberOfSpecimens
      });
      this.loadPrevCase();
      this.setLastAccession();
      if (this.IncludeSiteOnSpecimenLabel) {
        this.printSpecLabelsWithSite(previousCase.caseId);
      }
      if (previousCase.specimens?.length) {
        const prefix = await PrefixApi.searchStore.byKey(previousCase.labPrefix);
        if (prefix.printCassetteLabels) {
          await this.printBlocks(previousCase);
        }

        if (prefix.printSlideLabels) {
          await this.printSlides(previousCase);
        }
      }
    },
    loadSettings() {
      if (this.labSettings?.DefaultBillingType != undefined) {
        this.caseDetails.billingType = this.labSettings.DefaultBillingType;
      }
      if (this.labSettings?.DefaultBillCycle) {
        this.caseDetails.billingCycle = this.labSettings.DefaultBillCycle;
      }
      if (this.labSettings?.DefaultDateCollected) {
        if (this.labSettings?.DefaultDateCollected === 0) {
          this.caseDetails.collectedOn = this.today;
        } else if (this.labSettings?.DefaultDateCollected === 1) {
          this.caseDetails.collectedOn = this.caseDetails.receivedOn;
        } else if (this.labSettings.DefaultDateCollected === 2) {
          this.caseDetails.collectedOn = null;
        }
      }
    },
    setLastAccession() {
      if (!this.lastAccession) {
        this.lastAccessionLinkCase = {};
      } else if (this.lastAccession.caseNumber !== this.caseDetails.caseNumber) {
        this.lastAccessionLinkCase = this.lastAccession;
      }
    },
    checkAge() {
      if (
        this.labSettings?.MinDiffDobCollected &&
        moment(this.caseDetails.patientDOB) >
          moment().subtract(this.labSettings.MinDiffDobCollected, "days")
      ) {
        const confirm = window.confirm(
          `Warning: Patient age (${moment().diff(
            this.caseDetails.patientDOB,
            "days"
          )} days) is below the minimum allowed by lab settings (${
            this.labSettings.MinDiffDobCollected
          } days). Do you wish to continue?`
        );
        return confirm;
      }
      if (
        this.labSettings?.MaxDiffDobCollected &&
        moment(this.caseDetails.patientDOB) <
          moment().subtract(this.labSettings.MaxDiffDobCollected, "years")
      ) {
        const confirm = window.confirm(
          `Warning: Patient age (${moment().diff(
            this.caseDetails.patientDOB,
            "years"
          )} years) is above the maximum allowed by lab settings (${
            this.labSettings.MaxDiffDobCollected
          } years). Do you wish to continue?`
        );
        return confirm;
      }
      return true;
    },
    removeEmptyContactInfo() {
      if (this.caseDetails.addresses.length) {
        this.caseDetails.addresses = this.caseDetails.addresses.filter(e => {
          return e.line1 && ((e.city && e.state) || e.zipCode || e.international);
        });
      }
      if (this.caseDetails.emails.length) {
        this.caseDetails.emails = this.caseDetails.emails.filter(e => {
          return e.address || e.email;
        });
      }
      if (this.caseDetails.phoneNumbers.length) {
        this.caseDetails.phoneNumbers = this.caseDetails.phoneNumbers.filter(e => {
          return e.phoneNumber;
        });
      }
    },
    manuallyMatchPatient(data) {
      this.setPatient(data);
      this.hasManuallyMatched = true;
      if (this.isManuallyMatching) {
        this.historyMatchingPayload = null;
        this.historyMatchingStatus = "manual";
      } else {
        this.historyMatchingStatus = "accepted";
      }
      this.isManuallyMatching = false;
      if (this.labSettings?.UseADTOrders) {
        this.$nextTick(() => {
          this.focusOnNumberOfSpecimens();
        });
      } else if (this.labSettings?.SkipDemographicsatAccessioning) {
        this.$refs.specimenForm.focusProtocol();
      }
    },
    togglePatientHistoryPopup() {
      this.isHistoryOpen = !this.isHistoryOpen;
    },
    focusOnNumberOfSpecimens() {
      const numberRef =
        this.$refs.accession.$refs?.numberOfSpecimens[0] ||
        this.$refs.accession.$refs.numberOfSpecimens;
      this.$refs.accession.caseDetails.numberOfSpecimens = null;
      numberRef.focus();
      numberRef.selectAll("numberOfSpecimens");
    },
    async handleRegistrationNotes(caseDetails, registrationNotes) {
      const targetProtocol = this.prefixProtocols.find(
        e => e.id === this.currentPrefix?.protocolId
      );
      let gross = null;
      let specimens = [];
      let protocolId = null;
      if (targetProtocol) {
        protocolId = this.currentPrefix.protocolId;
        const protocolDetails = await MacrosApi.getMacroDetails(this.currentPrefix.protocolId);
        if (protocolDetails?.gross) {
          gross = protocolDetails.gross;
        }
      }
      for (let i = 0; i < Number(caseDetails.numberOfSpecimens); i++) {
        const newSpecimen = {
          caseId: caseDetails.caseId,
          protocolId: protocolId,
          hopperId: null,
          gross: gross || "",
          clinical: "",
          numberOfBlocks: 1,
          labId: this.currentLab,
          slides: 0,
          cassettes: [],
          newSpecimen: true,
          specimenOrder: getNextSpecimenOrder(specimens, this.specimenNumbering || 0),
          site: this.currentPrefix?.site || "",
          caseNotes: this.caseNotes
        };
        specimens.push(newSpecimen);
      }
      const addedSpecimens = await this.createCaseSpecimens(specimens);
      for (const registrationNote of registrationNotes) {
        const specimen = addedSpecimens[registrationNote.specimenIndex];
        await QuickLinksApi.addNoteTags({
          tagId: registrationNote.typeCode,
          caseId: caseDetails.caseId,
          specimenId: specimen.id,
          text: registrationNote.note,
          type: "T",
          typeCode: { id: registrationNote.typeCode }
        });
      }
    },
    handleCloseMatching() {
      this.isMatching = false;
      if (!this.isManuallyMatching) {
        this.historyMatchingStatus = "declined";
      }
      this.isManuallyMatching = false;
    },
    async setContactBillingType(contact) {
      if (!contact) {
        return;
      }
      if (!Object?.keys(contact).includes("properties")) {
        const [{ properties }] = await ProvidersApi.getContactsById([contact.contactId]);
        contact.properties = properties;
      }
      if (contact?.properties?.defaultBillingType != null) {
        this.caseDetails.billingType = contact.properties.defaultBillingType;
      } else {
        this.caseDetails.billingType = this.labSettings.DefaultBillingType || 0;
      }
    },
    async checkHistory(event) {
      if (this.historyMatchingStatus === "none") {
        const payload = createPatientMatchPayload(this.caseDetails);
        if (payload) {
          await this.matchPatient(payload);
          this.saveEventAfterMatch = event;
          return false;
        } else {
          this.historyMatchingStatus = "missing data";
          this.historyMatchingPayload = {
            dob: this.caseDetails.patientDOB,
            mrn: this.caseDetails.patientMRN,
            ssn: this.caseDetails.patientSSN,
            firstName: this.caseDetails.patientFirstName,
            lastName: this.caseDetails.patientLastName
          };
        }
      }
      return true;
    },
    async loadPrefix(prefixId) {
      const prefix = await PrefixApi.getPrefixById(prefixId);
      this.currentPrefix = prefix;
      DropdownApi.getProtocol(prefixId).then(res => {
        this.prefixProtocols = res || [];
      });
      if (prefix?.defaultContactId) {
        const contact = await ProvidersApi.getContactsById([prefix.defaultContactId]);
        this.prefixDefaultContact = contact[0];
      } else {
        this.prefixDefaultContact = null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.accession-wrapper {
  z-index: 99;
  border-radius: 3px;
  margin: 0px 0px 10px 20px;
  width: 100%;
  overflow-y: auto;
}

.accession-create {
  background: $white;
}

label {
  font-weight: 600;
  font-size: 0.85rem;
}

.header {
  background-color: $gray;
  padding: 10px 5px;
}
.header-panel {
  background-color: $gray;
  display: flex;
  padding: 10px 5px;
  text-align: left;
}
</style>
